.error-message-container {
  height: 100vh;
  min-height: 400px;
  position: relative;
  width: 100vw;
}

.error-message {
  color: rgb(204, 74, 74);
  font-weight: 600;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 45%;
  transform: translate(-50%, -45%);
  user-select: none;
}

.loading-container {
  display: flex;
  width: 90%;
  align-items: center;
  flex-flow: column;
  margin: auto;
}

.show-container {
  display: flex;
}

.hide-container {
  display: none;
}

@keyframes showLogout {
  to {
    visibility: visible;
  }
}

.button-container {
  max-width: 500px;
  margin-top: 1em;
  flex-flow: column;
  align-items: center;
  visibility: hidden;
  animation: showLogout 0s 7s forwards;
  text-align: center;
}

.logout-btn {
  margin-top: 0.5em;
  border: none;
  border-radius: 4px;
  padding: 0.5em 0.7em;
  color: white;

  background-color: #0095a0;
  cursor: pointer;
}

.loader-wheel {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border: 3px solid #f2fafa;
  border-radius: 50%;
  border-top-color: #0095a0;
  height: 3em;
  left: 8%;
  margin-bottom: 5px;
  position: relative;
  width: 3em;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-text {
  color: #0095a0;
  margin-bottom: 2px;
}

.please-wait-text {
  font-size: 14px;
}

.quality-docs-link {
  color: #0095a0;
  font-weight: 500;
  top: 50%;
  transform: translate(-50%, -50%);
  &:visited {
    color: #0095a0;
  }
}

a:link {
  text-decoration: none;
}

a:visited {
  color: #0095a0;
}

a:hover {
  text-decoration: underline;
}
