@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  height: 100%;
  width: 100%;
  /* background-color: black !important; */
  /* border: 3px solid cyan; */
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.custom-task-input:focus-within {
  @apply border-[#007983] !important;
}
