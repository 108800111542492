.loading {
  display: block;
  margin: auto;
  width: 20px;
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

.circle {
  fill: none;
  stroke: rgb(1, 192, 192);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}
//

.loading-container {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: relative;
  text-align: center;
  top: 7%;
  justify-content: center;

}

.loader-wheel {
  align-self: center;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border: 3px solid #f2fafa;
  border-radius: 50%;
  border-top-color: #0095a0;
  height: 3em;
  width: 3em;
}

.loading-text {
  color: #0095a0;
  margin: 20px;
}

.please-wait-text {
  font-size: 14px;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}
