// navbars
$sidenav-collapsed-width: 70px;
$sidenav-expanded-width: 300px;
$nav-height: 50px;

body {
  background: #fafbfb;
  box-sizing: border-box;
  @apply text-typography-dark;
  margin: 0;
  padding: 0;
  font-family: "Open Sans Variable", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.app-container {
  height: calc(100vh - $nav-height);
  width: 100vw;
}

.sidenav-expand {
  width: $sidenav-expanded-width;
}

.sidenav-collapased {
  width: $sidenav-collapsed-width;
}
